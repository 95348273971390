import React from 'react'
import { react, useState, useEffect, Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import axios from "axios"
import "./Season.css"
import Card from 'react-bootstrap/Card';
import "bootstrap/dist/css/bootstrap.min.css"
import Image from 'react-bootstrap/Image';
import ClipLoader from 'react-spinners/ClipLoader';


const Season = ({handleSelect}) => {
    const [season, setSeason] = useState([])
    const [loading, setLoading] = useState(false)
    const [textLoading, setTextLoading]= useState(false)
  
     
    useEffect(() => {
        setLoading(true)
        
        const fetchSeason = async () =>{
         try {
            const res = await axios.get(`https://us-west2-skilled-nation-385021.cloudfunctions.net/season/${handleSelect}`)
           
            
            setTimeout(() => {
                setLoading(false)
                setSeason(res.data)
             
              }, 1500);
         } catch (error) {
             console.log(error);
         }   
        

        }
        fetchSeason()
    }, [handleSelect])

    setTimeout(()=>{
        setTextLoading(true)
    },1800)


    return (
        <div className="Season">
            
             {loading ? <ClipLoader color={'maroon'} loading={loading} size={100}/> :
             
             season.map((item, value) => (
                <div className="inner_season" key={value}>
                { item.season_id == handleSelect ? 
                    <div className="season_layout">
                    <div className="season_image">
                    <Image src={require(`../img/Ranger/RangerLogo/${item.Name.toString().replace(/\s/g, "")}.JPG`)}
                       fluid rounded alt=""/>

                    </div>
                    { textLoading ? 
                <div className="season_data">
                <p> {item.Description}</p>
                 <p>Start Date: {item['Start Date']}</p>
                <p> End Date: {item['End Date']}</p>
                <p> Number of Episodes: {item["Number of episodes"]}</p>
                 </div>
                
              
              : null
              }
                    
                   
                    </div>
                    
                    
                    
                    : null}
                
                </div>))}
        </div>
    )
}

export default Season
