
import React from 'react'
import { react, useState, useEffect, Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import axios from "axios"
import "./Mentor_Villains.css"
import Card from 'react-bootstrap/Card';
import "bootstrap/dist/css/bootstrap.min.css"
import Image from 'react-bootstrap/Image';
import ClipLoader from 'react-spinners/ClipLoader';
import images from "../img/Ranger/Mentor_Villains/images.js"


const Mentor_Villains = ({handleSelect}) => {
    const [mentor_Villains, setMentor_Villains] = useState([])
    const [loading, setLoading] = useState(false)

    const img = (data) =>{
       
        
        return data.toString().replace(/\s/g, "")
    } 

    // const get_url_extension = ( url ) => {
    //     return url.split(/[#?]/)[0].split('.').pop().trim();
    // }


    useEffect(() => {
        setLoading(true)
        const fetchMentor_Villains = async () =>{
         try {
            const res = await axios.get(`https://us-west2-skilled-nation-385021.cloudfunctions.net/mentorVillains/${handleSelect}`)
           
            setTimeout(() => {
                setLoading(false)
                setMentor_Villains(res.data)
              }, 1900);
         } catch (error) {
             console.log(error);
         }   
        

        }
        fetchMentor_Villains()
    }, [handleSelect])

    

    return (
        <div className="Main"><h1>Mentor / Villains</h1>

        <div className="Mentor_Villains">
              
             
             {loading ? <ClipLoader color={'maroon'} loading={loading} size={100}/> :
             
             
             mentor_Villains.map((item, value) => (
                <div className="inner_Mentor_Villains" key={value}>
                { item.season_id == handleSelect ? 
                    <Card bg="secondary" text="light" border="light" style={{ width: '18rem'}}>

                    <Card.Img variant="top" src={images[img(item.Name)]} />                  
                    
                        <Card.Body>
                        <Card.Title> <h1>{item.Name}</h1></Card.Title>
                        <Card.Subtitle ><h2>{item.Actor}</h2></Card.Subtitle>
                        <Card.Text>
                            {item.Role}
                            
                        </Card.Text>
                    </Card.Body>
                </Card>
            
                 

                    
                    
                    
                    : null}
                
                </div>))}
            </div>
        </div>
    )
}

export default Mentor_Villains
