import Zordon from "../Mentor_Villains/Zordon.jpeg"
import Alpha5 from "../Mentor_Villains/Alpha\ 5.jpeg"

import RitaRepulsa from "../Mentor_Villains/Rita\ Repulsa.jpeg"
import Goldar from "../Mentor_Villains/Goldar.jpeg"
import Squatt from "../Mentor_Villains/Squatt.jpeg"
import Baboo from "../Mentor_Villains/Baboo.jpeg"
import Finster from "../Mentor_Villains/Finster.jpeg"
import Scorpina from "../Mentor_Villains/Scorpina.jpeg"


import LordZedd from "../Mentor_Villains/Lord\ Zedd.jpeg"
import RitoRevolto from "../Mentor_Villains/Rito\ Revolto.jpeg"
import MasterVile from "../Mentor_Villains/Master\ Vile.jpeg"
import Putty from "../Mentor_Villains/Putty.jpeg"
import SuperPutty from "../Mentor_Villains/Super\ Putty.jpeg"
import ZPutty from "../Mentor_Villains/Z\ Putty.jpeg"
import TengaWarriors from "../Mentor_Villains/Tenga\ Warriors.jpeg"


import KingMondo from "../Mentor_Villains/King\ Mondo.jpeg"
import QueenMachina from "../Mentor_Villains/Queen\ Machina.jpeg"
import PrinceSprocket from "../Mentor_Villains/Prince\ Sprocket.jpeg"
import Klank from "../Mentor_Villains/Klank.jpeg"
import PrinceGasket from "../Mentor_Villains/Prince\ Gasket.jpeg"
import PrincessArcherina from "../Mentor_Villains/Princess\ Archerina.jpeg"


import Alpha6 from "../Mentor_Villains/Alpha\ 6.jpeg"
import Cogs from "../Mentor_Villains/Cogs.jpeg"
import Orbus from "../Mentor_Villains/Orbus.jpeg"
import Dimitria from "../Mentor_Villains/Dimitria.jpeg"
import Divatox from "../Mentor_Villains/Divatox.jpeg"
import Elgar from "../Mentor_Villains/Elgar.jpeg"


import Rygog from "../Mentor_Villains/Rygog.jpeg"
import Porto from "../Mentor_Villains/Porto.jpeg"
import Piranhatrons from "../Mentor_Villains/Piranhatrons.jpeg"


import DECA from "../Mentor_Villains/D.E.C.A.png"


import DarkSpecter from "../Mentor_Villains/Dark\ Specter.jpeg"
import Astronema from "../Mentor_Villains/Astronema.jpeg"
import Ecliptor from "../Mentor_Villains/Ecliptor.jpeg"
import Darkonda from "../Mentor_Villains/Darkonda.jpeg"
import Quantrons from "../Mentor_Villains/Quantrons.jpeg"
import PsychoRedRanger from "../Mentor_Villains/Psycho\ Red\ Ranger.jpeg"
import PsychoBlueRanger from "../Mentor_Villains/Psycho\ Blue\ Ranger.jpeg"
import PsychoBlackRanger from "../Mentor_Villains/Psycho\ Black\ Ranger.jpeg"
import PsychoPinkRanger from "../Mentor_Villains/Psycho\ Pink\ Ranger.jpeg"
import PsychoYellowRanger from "../Mentor_Villains/Psycho\ Yellow\ Ranger.jpeg"
import CommanderStanton from "../Mentor_Villains/Commander\ Stanton.png"


import CaptainMutiny from "../Mentor_Villains/Captain\ Mutiny.jpeg"
import Scorpius from "../Mentor_Villains/Scorpius.jpeg"
import Trakeena from "../Mentor_Villains/Trakeena.jpeg"
import Furio from "../Mentor_Villains/Furio.jpeg"
import Treacheron from "../Mentor_Villains/Treacheron.jpeg"
import Deviot from "../Mentor_Villains/Deviot.jpeg"
import Villamax from "../Mentor_Villains/Villamax.jpeg"
import Stingwingers from "../Mentor_Villains/Stingwingers.jpeg"


import CaptainMitchell from "../Mentor_Villains/Captain\ Mitchell.jpeg"
import AngelaFairweather from "../Mentor_Villains/Angela\ Fairweather.jpeg"

import QueenBansheera from "../Mentor_Villains/Queen\ Bansheera.jpeg"
import PrinceOlympius from "../Mentor_Villains/Prince\ Olympius.jpeg"
import Diabolico from "../Mentor_Villains/Diabolico.jpeg"
import Loki from "../Mentor_Villains/Loki.jpeg"
import Vypra from "../Mentor_Villains/Vypra.jpeg"
import Jinxer from "../Mentor_Villains/Jinxer.jpeg"

import Batlings from "../Mentor_Villains/Batlings.jpeg"
import Ransik from "../Mentor_Villains/Ransik.jpeg"
import Nadira from "../Mentor_Villains/Nadira.jpeg"
import Frax from "../Mentor_Villains/Frax.jpeg"
import Cyclobots from "../Mentor_Villains/Cyclobots.jpeg"
import PrincessShayla from "../Mentor_Villains/Princess\ Shayla.jpeg"

import MasterOrg from "../Mentor_Villains/Master\ Org.jpeg"
import Putrids from "../Mentor_Villains/Putrids.jpeg"
import SenseiKanoiWatanabe from "../Mentor_Villains/Sensei\ Kanoi\ Watanabe.jpeg"
import Lothor from "../Mentor_Villains/Lothor.jpeg"
import Marah from "../Mentor_Villains/Marah.jpeg"
import Kapri from "../Mentor_Villains/Kapri.jpeg"

import Kelzaks from "../Mentor_Villains/Kelzaks.jpeg"
import HayleyZiktor from "../Mentor_Villains/Hayley\ Ziktor.jpeg"
import Mesogog from "../Mentor_Villains/Mesogog.jpeg"
import Elsa from "../Mentor_Villains/Elsa.jpeg"
import Zeltrax from "../Mentor_Villains/Zeltrax.jpeg"
import Triptoids from "../Mentor_Villains/Triptoids.jpeg"

import AnubisCruger from "../Mentor_Villains/Anubis\ Cruger.jpeg"
import OmniTheMagnificence from "../Mentor_Villains/Omni\ The\ Magnificence.png"
import EmperorGruumm from "../Mentor_Villains/Emperor\ Gruumm.jpeg"
import Morgana from "../Mentor_Villains/Morgana.jpeg"
import Broodwing from "../Mentor_Villains/Broodwing.jpeg"

import ASquadRedRanger from "../Mentor_Villains/A-Squad\ Red\ Ranger.jpeg"
import ASquadBlueRanger from "../Mentor_Villains/A-Squad\ Blue\ Ranger.jpeg"
import ASquadYellowRanger from "../Mentor_Villains/A-Squad\ Yellow\ Ranger.jpeg"
import ASquadPinkRanger from "../Mentor_Villains/A-Squad\ Pink\ Ranger.jpeg"
import ASquadGreenRanger from "../Mentor_Villains/A-Squad\ Green\ Ranger.jpeg"

import OctomustheMaster from "../Mentor_Villains/Octomus\ the\ Master.jpeg"
import Krybots from "../Mentor_Villains/Krybots.jpeg"
import Udonna from "../Mentor_Villains/Udonna.jpeg"
import Clare from "../Mentor_Villains/Clare.jpeg"
import KoraggTheKnightWolf from "../Mentor_Villains/Koragg\ The\ Knight\ Wolf.jpeg"
import Hidiacs from "../Mentor_Villains/Hidiacs.png"

import AndrewHartford from "../Mentor_Villains/Andrew\ Hartford.jpeg"
import Spencer from "../Mentor_Villains/Spencer.png"
import Thrax from "../Mentor_Villains/Thrax.jpeg"
import Flurious from "../Mentor_Villains/Flurious.jpeg"
import Chillers from "../Mentor_Villains/Chillers.jpeg"
import Moltor from "../Mentor_Villains/Moltor.jpeg"


import LavaLizards from "../Mentor_Villains/Lava\ Lizards.jpeg"
import Kamdor from "../Mentor_Villains/Kamdor.jpeg"
import Miratrix from "../Mentor_Villains/Miratrix.jpeg"
import RJ from "../Mentor_Villains/RJ.jpeg"
import DaiShi from "../Mentor_Villains/Dai\ Shi.png"
import Jarrod from "../Mentor_Villains/Jarrod.jpeg"

import DoctorK from "../Mentor_Villains/Doctor\ K.jpeg"
import Rinshi from "../Mentor_Villains/Rinshi.jpeg"
import ColonelMasonTruman from "../Mentor_Villains/Colonel\ Mason\ Truman.jpeg"
import Venjix from "../Mentor_Villains/Venjix.jpeg"
import Grinders from "../Mentor_Villains/Grinders.jpeg"
import Tenaya from "../Mentor_Villains/Tenaya.jpeg"

import GeneralCrunch from "../Mentor_Villains/General\ Crunch.jpeg"
import GeneralShifter from "../Mentor_Villains/General\ Shifter.jpeg"
import MentorJi from "../Mentor_Villains/Mentor\ Ji.jpeg"
import MasterXandred from "../Mentor_Villains/Master\ Xandred.jpeg"
import Spitfangs from "../Mentor_Villains/Spitfangs.jpeg"
import Dayu from "../Mentor_Villains/Dayu.jpeg"

import AdmiralMalkor from "../Mentor_Villains/Admiral\ Malkor.jpeg"
import Serrator from "../Mentor_Villains/Serrator.jpeg"
import Deker from "../Mentor_Villains/Deker.jpeg"
import Octoroo from "../Mentor_Villains/Octoroo.jpeg"
import Moogers from "../Mentor_Villains/Moogers.jpeg"
import Gosei from "../Mentor_Villains/Gosei.png"
import Tensou from "../Mentor_Villains/Tensou.jpeg"

import EmperorMavro from "../Mentor_Villains/Emperor\ Mavro.jpeg"
import Vrak from "../Mentor_Villains/Vrak.jpeg"
import Loogies from "../Mentor_Villains/Loogies.jpeg"
import PrinceVekar from "../Mentor_Villains/Prince\ Vekar.jpeg"
import Levira from "../Mentor_Villains/Levira.jpeg"
import Damaras from "../Mentor_Villains/Damaras.jpeg"


// import  from "../Mentor_Villains/.jpeg"
// import  from "../Mentor_Villains/.jpeg"
// import  from "../Mentor_Villains/.jpeg"
// import  from "../Mentor_Villains/.jpeg"
// import  from "../Mentor_Villains/.jpeg"

import Argus from "../Mentor_Villains/Argus.jpeg"
import XBorgs from "../Mentor_Villains/X\ Borgs.png"
import Keeper from "../Mentor_Villains/Keeper.jpeg"
import Sledge from "../Mentor_Villains/Sledge.jpeg"
import Fury from "../Mentor_Villains/Fury.png"

import Wrench from "../Mentor_Villains/Wrench.jpeg"
import Poisandra from "../Mentor_Villains/Poisandra.jpeg"
import Curio from "../Mentor_Villains/Curio.jpeg"
import Vivix from "../Mentor_Villains/Vivix.jpeg"
import Snide from "../Mentor_Villains/Snide.jpeg"


import LordArcanon from "../Mentor_Villains/Lord\ Arcanon.jpeg"
import MickKanic from "../Mentor_Villains/Mick\ Kanic.jpeg"
import Galvanax from "../Mentor_Villains/Galvanax.jpeg"
import Kudabots from "../Mentor_Villains/Kudabots.jpeg"
import Basherbots from "../Mentor_Villains/Basherbots.jpeg"

import Ripcon  from "../Mentor_Villains/Ripcon.jpeg"
import MadameOdius from "../Mentor_Villains/Madame\ Odius.jpeg"
import Redbot from "../Mentor_Villains/Redbot.png"
import  Foxbots from "../Mentor_Villains/Foxbots.png"
import UpgradedBasherbots from "../Mentor_Villains/Upgraded\ Basherbots.png"

import Badonna from "../Mentor_Villains/Badonna.jpeg"
import CommanderShaw from "../Mentor_Villains/Commander\ Shaw.jpeg"
import Evox from "../Mentor_Villains/Evox.jpeg"
import Blaze from "../Mentor_Villains/Blaze.jpeg"
import Roxy from "../Mentor_Villains/Roxy.jpeg"


import Scrozzle from "../Mentor_Villains/Scrozzle.jpeg"
import Tronics from "../Mentor_Villains/Tronics.jpeg"
import Solon from "../Mentor_Villains/Solon.jpeg"
import VoidKnight from "../Mentor_Villains/Void\ Knight.jpeg"


import VoidQueen from "../Mentor_Villains/Void\ Queen.png"
import Mucus from "../Mentor_Villains/Mucus.jpeg"
import Boomtower from "../Mentor_Villains/Boomtower.jpeg"
import Slyther from "../Mentor_Villains/Slyther.jpeg"
import Wreckmate from "../Mentor_Villains/Wreckmate.jpeg"

import Hengemen from "../Mentor_Villains/Hengemen.png"
import Lothorn from "../Mentor_Villains/Lothorn.png"






const images = {
    Zordon,
    Alpha5,
RitaRepulsa,
Goldar,
Squatt,
Baboo,
Finster,
Scorpina,
LordZedd,
RitoRevolto,
MasterVile,
Putty,
SuperPutty,
ZPutty,
TengaWarriors,

KingMondo,
QueenMachina,
PrinceSprocket,
Klank,
PrinceGasket,
PrincessArcherina,
Cogs,
Orbus,

Alpha6,
Dimitria,
Divatox,
Elgar,
Rygog,
Porto,
Piranhatrons,

DECA,
DarkSpecter,
Astronema,
Ecliptor,

Darkonda,
Quantrons,


PsychoRedRanger,
PsychoBlueRanger,
PsychoBlackRanger,
PsychoPinkRanger,
PsychoYellowRanger,
CommanderStanton,
Scorpius,
Trakeena,
Furio,
Treacheron,
Deviot,
Villamax,

Stingwingers,
CaptainMutiny,
CaptainMitchell,
AngelaFairweather,
QueenBansheera,
PrinceOlympius,
Diabolico,
Loki,
Vypra,
Jinxer,
Batlings,
 Ransik,
Nadira,
Frax,
Cyclobots,
PrincessShayla,
MasterOrg,
Putrids,
SenseiKanoiWatanabe,
Lothor,
Marah,
Kapri,
Kelzaks,
HayleyZiktor,
Mesogog,
Elsa,
Zeltrax,
Triptoids,
AnubisCruger,
OmniTheMagnificence,
EmperorGruumm,
Morgana,
Broodwing,
ASquadRedRanger,
ASquadBlueRanger,
ASquadGreenRanger,
ASquadYellowRanger,
ASquadPinkRanger,
Krybots,
Udonna,
Clare,
OctomustheMaster,



KoraggTheKnightWolf,


Hidiacs,
AndrewHartford,
Spencer,
Thrax,
Flurious,
Chillers,
Moltor,
LavaLizards,
Kamdor,
Miratrix,
RJ,
DaiShi,
Jarrod,
Rinshi,
DoctorK,
ColonelMasonTruman,
Venjix,
Grinders,
Tenaya,
GeneralCrunch,
GeneralShifter,
MentorJi,
MasterXandred,
Spitfangs,
Dayu,
Serrator,
Deker,
Octoroo,
Moogers,
Serrator,
Gosei,
Tensou,
AdmiralMalkor,
Vrak,
Loogies,
EmperorMavro,
PrinceVekar,
Levira,
Damaras,
Argus,
XBorgs,
Keeper,
Sledge,
Fury,
Wrench,
Poisandra,
Curio,
Vivix,
Snide,
LordArcanon,
MickKanic,
Galvanax,
Kudabots,
Basherbots,
Ripcon,
MadameOdius,
Redbot,
Foxbots,
UpgradedBasherbots,
Badonna,
CommanderShaw,
Evox,
Blaze,
Roxy,
Scrozzle,
Tronics,
Sledge,
Solon,
VoidKnight,
VoidQueen,
Mucus,
Boomtower,
Slyther,
Wreckmate,
Hengemen,
Lothorn,

}
export default images

// function importAll(r) {
//     return r.keys().map(r);
//   }
  
// const img = importAll(require.context('../Mentor_Villains/', false, /\.(png|jpe?g|svg)$/));
// console.log(img);


