import logo from './logo.svg';
import './App.css';
import {react,useState,useEffect} from "react";
import {BrowserRouter,Routes, Route} from "react-router-dom";
import Rangers from "./pages/Rangers"
import Season from './pages/Season';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import "bootstrap/dist/css/bootstrap.min.css"
import Container from 'react-bootstrap/Container';
import Mentor_Villains from './pages/Mentor_Villains';
import ClipLoader from "react-spinners/ClipLoader";
import Disclaimer from "./pages/info/Disclaimer";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';
import Footer from './pages/info/Footer'



function App() {
  const [currentSeason, setCurrentSeason] = useState('1')
  const handleSelect = (e) =>{
    console.log("In main");
    console.log(e);
    setCurrentSeason(e)
  }


  return (
    <div className="App">
      
      <Navbar  variant="dark" bg="dark" expand="lg"  >
      <Container fluid >
        <Navbar.Brand>Welcome to Rangers App</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-dark-example" />
        <Navbar.Collapse id="navbar-dark-example" >
          <Nav
         
          >
            <NavDropdown
            
              id="nav-dropdown-dark-example"
              title="Ranger Season"
              menuVariant="dark"
              onSelect={handleSelect}
              // className="App-Dropdown"
             
              
            >
              <NavDropdown.Item  eventKey="1">Mighty Morphin / Alien Ranger</NavDropdown.Item>
              <NavDropdown.Item  eventKey="2"> Zeo</NavDropdown.Item>
              <NavDropdown.Item  eventKey="3">Turbo</NavDropdown.Item>
              <NavDropdown.Item  eventKey="4">In Space</NavDropdown.Item>
              <NavDropdown.Item  eventKey="5">Lost Galaxy</NavDropdown.Item>
              <NavDropdown.Item  eventKey="6">Lightspeed Rescue</NavDropdown.Item>
              <NavDropdown.Item  eventKey="7">Time Force</NavDropdown.Item>
              <NavDropdown.Item  eventKey="8">Wild Force</NavDropdown.Item>
              <NavDropdown.Item  eventKey="9">Ninja Storm</NavDropdown.Item>
              <NavDropdown.Item  eventKey="10">Dino Thunder</NavDropdown.Item>
              <NavDropdown.Item  eventKey="11">S.P.D.</NavDropdown.Item>
              <NavDropdown.Item  eventKey="12">Mystic Force</NavDropdown.Item>
              <NavDropdown.Item  eventKey="13">Operation Overdrive</NavDropdown.Item>
              <NavDropdown.Item  eventKey="14">Jungle Fury</NavDropdown.Item>
              <NavDropdown.Item  eventKey="15">R.P.M.</NavDropdown.Item>
              <NavDropdown.Item  eventKey="16">Samurai / Super Samurai</NavDropdown.Item>
              <NavDropdown.Item  eventKey="17">Megaforce / Super Megaforce</NavDropdown.Item>
              <NavDropdown.Item  eventKey="18">Dino Charge / Dino Super Charge</NavDropdown.Item>
              <NavDropdown.Item  eventKey="19">Ninja Steel / Ninja Super Steel</NavDropdown.Item>
              <NavDropdown.Item  eventKey="20">Beast Morphers</NavDropdown.Item>
              <NavDropdown.Item  eventKey="21">Dino Fury</NavDropdown.Item>

              
              
             
            </NavDropdown>
          </Nav>
         

          <Nav className="ms-auto">
          <OverlayTrigger
          trigger="click"
          key={'bottom'}
          placement={'bottom'}
          rootClose={true}
          
          overlay={
            <Popover id={`popover-positioned-${'bottom'}`}>
              <Popover.Header as="h3">Info</Popover.Header>
              <Popover.Body>
                <Disclaimer/>
              </Popover.Body>
            </Popover>
          }
        >
          <Button  variant="secondary">Disclaimer</Button>
        </OverlayTrigger>
          <Nav.Link  href="https://ordunodev21.com/">Portfolio</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

    
        
    

        <BrowserRouter>
        <Routes>
          
        <Route path="/" element={<><Season handleSelect={currentSeason}/> 
        <Rangers handleSelect={currentSeason}/>
        <Mentor_Villains handleSelect={currentSeason} /> </>} />
        
         
          
        </Routes>
      </BrowserRouter>
       
       <Footer/>

    </div>
  );
}

export default App;
