import React, {useState} from 'react';
import './footer.css';


const Footer = () => {
    return (
        <div className="app__footer">
           <div className="copyright">
                    @2023 Angel All Rights Reserved 
                    
                </div>
        </div>
    )
}

export default Footer
