import React from 'react'
import { react, useState, useEffect, Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import axios from "axios"
import "./Rangers.css"
import Card from 'react-bootstrap/Card';
import "bootstrap/dist/css/bootstrap.min.css"
import ClipLoader from 'react-spinners/ClipLoader';
 


const Rangers = ({handleSelect}) => {
    const [powerRanger, setPoweRanger] = useState([])
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        setLoading(true)
        const fetchRangers = async () => {
            try {
                const res = await axios.get(`https://us-west2-skilled-nation-385021.cloudfunctions.net/rangers/${handleSelect}`)
                setTimeout(() => {
                    setLoading(false)
                    setPoweRanger(res.data)
                  }, 1700);
            } catch (error) {
                console.log(error)
            }
        }
        fetchRangers()
    }, [handleSelect])

    return (
        <div className="Main">
            <h1>Rangers</h1>

        <div className="Rangers">
            

          
           {
              loading ? <ClipLoader color={'maroon'} loading={loading} size={100}/> : 
               
               
               powerRanger.map((ranger, value) => (
                <div className="ranger" key={value}>
                    {ranger.season_id == handleSelect ? <Card bg="secondary" text="light" border="light" style={{ width: '18rem'}}>
                        <Card.Img variant="top" src={require(`../img/Ranger/${ranger.Season.toString().replace(/\s/g, "")}/${ranger.Season.toString().replace(/\s/g, "")}-${ranger.Color.toString().replace(/\s/g, "")}.JPG`) } />
                        <Card.Body>
                            <Card.Title> <h1>{ranger.Season}</h1></Card.Title>
                            <Card.Subtitle ><h2>{ranger.Actor}</h2></Card.Subtitle>
                            <Card.Text>
                                {ranger.Color}
                                <br/>
                                {ranger['Ranger Name']}
                            </Card.Text>
                        </Card.Body>
                    </Card>

                    
                        : null}




                </div>

            ))}
        </div>

</div>

   )
}

export default Rangers
