import React from 'react'

const Disclaimer = () => {
    return (
        <div>
            All information is used for educational purposes. 
            The data was researched and found on the web.
            This APP is not affiliated WITH Power Rangers or Hasbro.
            All images were found on Pinterest.
        </div>
    )
}

export default Disclaimer
